<template>
  <div
    v-show="isPopShow"
    class=" fixed inset-x-0 bottom-0 z-50 flex  bg-gray-50 shadow-2xl rounded-t-md "
    :class="
      `${isFullView ? 'h-70vh' : 'h-10'} ${
        getSmallScreenStatus ? 'mx-5 w-auto' : 'ml-16 w-96'
      }`
    "
  >
    <div class="w-full">
      <loading
        v-if="!isFullView"
        :active="isLoading"
        :height="25"
        :opacity="0"
        :color="`#fff`"
        :loader="`bars`"
        :is-full-page="false"
        :class="`outline-none`"
      />
      <div
        class="h-10 bg-gray-900 rounded-t-md flex justify-between items-center px-4"
      >
        <div class="text-oWhite font-medium">
          <p>
            {{ headline }}
          </p>
        </div>
        <div class="flex gap-4 items-center justify-center">
          <div class="cursor-pointer" @click="handleReload">
            <i class="text-base fas text-oWhite fa-rotate-right"></i>
          </div>
          <div class="cursor-pointer" @click="toggleView">
            <i
              class="text-base fas text-oWhite"
              :class="
                `${
                  isFullView
                    ? 'fa-down-left-and-up-right-to-center'
                    : 'fa-up-right-and-down-left-from-center'
                }`
              "
            />
          </div>
          <div class="cursor-pointer" @click="handlePopClose">
            <i class="text-base fas text-oWhite fa-xmark"></i>
          </div>
        </div>
      </div>
      <div class="px-2 relative">
        <loading
          v-if="isFullView"
          :active="isLoading"
          :height="25"
          :opacity="0.8"
          :color="`#000`"
          :loader="`bars`"
          :is-full-page="false"
          :class="`outline-none`"
        />
        <div class=" mt-2  flex items-center gap-2">
          <div
            :key="`${step.id}-${stepIndex}`"
            v-for="(step, stepIndex) in getSuggestions"
            class="border-2 border-t-2  w-full rounded"
            :class="`${step.isCompleted ? 'border-green-500' : ''}`"
          ></div>
        </div>
        <div class="my-2">
          <p class="text-sm text-oGray text-center">
            {{ getCompletedSuggestionsCount }} of
            {{ getSuggestions.length }} completed
          </p>
        </div>
        <div class="py-2 overflow-auto max-h-55vh">
          <div
            :key="step.id"
            v-for="(step, stepIndex) in getSuggestions"
            class="flex gap-3 py-2"
          >
            <div class="relative">
              <div
                class="top-0 left-0 flex items-center justify-center bg-gray-200 rounded-full w-8 h-8"
              >
                <i
                  v-if="step.isCompleted"
                  class="fa fa-check-circle text-green-600"
                />
                <span v-else class="bg-gray-900 badge">{{
                  stepIndex + 1
                }}</span>
              </div>
            </div>
            <div class="flex gap-4 justify-around">
              <div>
                <p class="font-medium">{{ step.title }}</p>
                <p class="text-sm text-gray-500 text-justify">
                  {{ step.subtitle }}
                </p>
              </div>
              <div class="mr-2">
                <router-link :to="step.path" target="_blank">
                  <i
                    class="fas fa-arrow-up-right-from-square text-blue-600 text-sm cursor-pointer"
                  ></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PopSuggestion',
  props: {
    headline: {
      type: String,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    isLoading() {
      return this.$store.getters['suggestiveStep/getReqStatus']
    },

    isFullView: {
      get() {
        return this.$store.getters['suggestiveStep/getFullViewStatus']
      },
      set(val) {
        this.$store.commit('suggestiveStep/SET_FULL_VIEW_STATUS', val)
      },
    },
    isPopShow: {
      get() {
        return this.$store.getters['suggestiveStep/getPopViewStatus']
      },
      set(val) {
        this.$store.commit('suggestiveStep/SET_POPUP_VIEW_STATUS', val)
      },
    },
    getSuggestions() {
      return this.$store.getters['suggestiveStep/getSuggestions']
    },
    getCompletedSuggestionsCount() {
      return this.$store.getters['suggestiveStep/getCompletedSuggestions']
        .length
    },
    getSmallScreenStatus() {
      return this.$store.getters['screen/getSmallScreenStatus']
    },
  },
  methods: {
    toggleView() {
      this.isFullView = !this.isFullView
    },
    handlePopClose() {
      this.isPopShow = false
    },
    handleReload() {
      this.$store.dispatch('suggestiveStep/fetchData')
    },
  },
}
</script>
<style lang="scss">
.connected-down {
  left: 15px;
  bottom: 0;
  top: 40%;
}

.connected-up {
  left: 15px;
  top: -1px;
  bottom: 40%;
}

.badge {
  @apply w-5 h-5 text-sm flex items-center justify-center p-1 rounded-full text-oWhite;
}

.h-70vh {
  height: 70vh;
}
.max-h-55vh {
  max-height: 55vh;
}
</style>
