<template>
  <div :id="`${fstId}Container`" class="fst-container ">
    <!-- top -->
    <section>
      <div
        class="flex flex-col px-4 mb-5 space-y-3 md:space-y-0 md:flex-row md:justify-between"
      >
        <!-- left -->
        <div
          class="flex flex-col space-y-3 space-x-0 w-full md:space-x-3 md:space-y-0 md:items-center md:flex-row"
        >
          <div>
            <OtoSearch v-if="searchEnabled" v-model="currentSearch" />
          </div>

          <section
            ref="topLeftSlot"
            class="flex flex-col w-full md:flex-row md:max-w-md lg:max-w-lg xl:max-w-xl"
          >
            <slot name="topLeft" v-bind:slotWidth="topLeftSlotWidth" />
          </section>
        </div>

        <!-- right -->
        <div
          class="flex flex-col gap-3 justify-center w-full md:flex-row  md:items-center md:justify-end"
        >
          <slot name="topRight" />

          <MultipleTableViewButton
            v-if="isMultipleViewEnabled"
            :selectedView="layoutView"
            @change="onChangeView"
          />
          <SyncWithAIButton
            v-if="isAISyncEnabled"
            :isClicked="isAiSyncInOperation || isAISyncStepChecking"
            :isLoading="isAISyncStepChecking"
            @sync="onSyncWithAI"
          />

          <CurrencyDropdown v-if="currencyEnabled" @change="onChangeCurrency" />

          <ExportAsDropdown
            v-if="isExportEnabled"
            :isQrCodeExport="isQrCodeDownloadEnabled"
            @csv="exportAs('csv')"
            @excel="exportAs('excel')"
            @download-qr="exportQrCode"
            @cancel-export="handleCancelExport"
          />
        </div>
      </div>
    </section>
    <!-- /top -->

    <!-- table -->
    <section :id="`${fstId}Wrapper`" class="fst-wrapper">
      <loading
        :active.sync="reqBusy"
        :is-full-page="false"
        :z-index="10"
        :opacity="0.25"
      />
      <div class="w-96 " v-if="isExportLoading">
        <loading
          :active.sync="isExportLoading"
          :is-full-page="true"
          :z-index="10"
          :opacity="0.75"
        >
          <div class="p-5 bg-gray-100 rounded-2xl shadow-xl">
            <p>
              {{ isAiSyncInOperation ? 'Sync' : 'Download' }} in Progress:{{
                getProgress
              }}%
            </p>
            <div class="w-72">
              <BaseProgressBar color="blue" :percentage="getProgress" />
            </div>
            <div class="mt-5 flex justify-end">
              <button
                class="
          border-0
          rounded-md
          bg-gray-200
          text-gray-900
          px-3
          py-1
          hover:opacity-90
          transition-all
          font-semibold
          ease-linear
        "
                @click="handleCancelExport"
              >
                {{ $t('components.stepNavigation.cancel') }}
              </button>
            </div>
          </div>
        </loading>
      </div>

      <table :id="`${fstId}Table`" class="fst">
        <!-- header -->
        <thead class="fst-head" v-if="layoutView === 'list'">
          <tr class="fst-head-row">
            <div class="ml-3 " v-if="isHeaderChecked">
              <TCheckbox
                :name="`selectAllFromList`"
                :variant="`lg`"
                wrapped
                :checked="getAllSelectCheckStatus"
                @change="onChangeCheckBox"
              />
            </div>
            <th
              v-for="(header, headerIndex) in proxy.headers"
              :key="`fst-head-row-item-${headerIndex}`"
              :width="header.width"
              :class="
                `fst-head-row-item ${header.sort ? 'cursor-pointer' : ''}`
              "
              @click="onSort(header, headerIndex)"
            >
              {{ header.text }}

              <span class="text-blue-800">
                {{
                  header.order === 'asc'
                    ? '⇡'
                    : header.order === 'desc'
                    ? '⇣'
                    : ' '
                }}
              </span>
            </th>
          </tr>
        </thead>
        <!-- /header -->

        <!-- body -->
        <tbody
          v-if="getData"
          :class="layoutView === 'list' ? 'fst-body' : 'fst-grid-body'"
        >
          <!-- use row & row item -->
          <slot :data="getData" />
        </tbody>
        <!-- /body -->
      </table>

      <transition>
        <div
          v-if="!reqBusy && (!getData || getData.length === 0)"
          class="flex flex-col justify-center items-center p-5 my-8 w-full text-center"
        >
          <img
            src="@/assets/placeholder/no-data-undraw.svg"
            class="w-44 h-44 animate-pulse"
            alt="No Data"
          />
          <div class="mt-5 font-semibold text-gray-500 text-14px">
            Oops! No Data Found
            <i class="text-base far fa-meh-blank" />
          </div>
        </div>
      </transition>
    </section>
    <!-- /table -->

    <!-- bottom -->
    <section
      class="flex flex-col items-center px-2 w-full md:flex-row md:justify-between"
    >
      <div
        class="flex justify-center items-center mt-6 w-full md:justify-start md:w-1/3 md:mt-0"
      >
        <span>Show</span>

        <t-select
          v-model.number="currentLimit"
          variant="tableEntry"
          class="ml-2"
          :options="[
            { value: 10, text: '10' },
            { value: 15, text: '15' },
            { value: 25, text: '25' },
            { value: 50, text: '50' },
            { value: 100, text: '100' },
          ]"
        />

        <span class="ml-2">Entries</span>
      </div>

      <div class="hidden w-1/3 text-center md:block">
        {{ currentPaginationSummary }}
      </div>

      <div class="w-full md:w-1/3 md:flex md:items-end md:justify-end">
        <t-pagination
          :total-items="currentTotal"
          :per-page="currentLimit"
          v-model="currentPage"
          class="mt-6 mb-4"
          @change="onChangePage($event)"
        />
      </div>
    </section>
    <!-- /bottom -->
    <!-- <div class="w-56 h-20 bg-oRed absolute bottom-0 left-0"> -->
    <PopSuggestion headline="Sync with AI (BETA)" />
  </div>
  <!-- </div> -->
</template>

<script>
// filter components should only generate queryStrings
// filter qs need to be passed as url prop, fst qs option.prepend = &
// the qs will be merged together by fst
// todo: use store for all of it
// todo: use events
// todo: create a higher order class
// todo: use custom event system
// todo: make hooks for changing queryString

import OtoSearch from '@/components/ui/OtoSearch'
import BaseProgressBar from '@/components/progress-bar/BaseProgressBar.vue'
import ExportAsDropdown from '@/components/dropdown/ExportAsDropdown'
import CurrencyDropdown from '@/components/dropdown/CurrencyDropdown'
import MultipleTableViewButton from '@/components/button/MultipleTableViewButton.vue'
// import js2excel from 'js2excel'
import { saveAs } from 'file-saver'
import jsPDF from 'jspdf'
import QRCode from 'qrcode'
import { exportHeaderGroup, getTransformRowData } from '@/utils/export-data'
import { useEndpoints } from '@/composables'
import SyncWithAIButton from '../button/SyncWithAIButton.vue'
import PopSuggestion from '../suggestive-step/PopSuggestion.vue'
import { STEP_SUGGESTIONS, STEP_TOPIC } from '../suggestive-step/helper'
export default {
  name: 'FSTable',
  props: {
    // also used as the export name (camelCase converted to snake_case)
    fstId: {
      type: [String, Number],
      required: true,
    },
    endpoint: {
      type: [String, Number],
      required: true,
    },
    // query string option
    qso: {
      type: Object,
      required: false,
      default: () => ({
        prepend: '?',
        append: '',
      }),
    },
    headers: {
      type: Array,
    },
    exportFor: {
      type: String,
      default: '',
    },
    exportType: {
      type: Number,
    },
    exportFromURL: {
      type: Boolean,
      default: false,
    },

    // currency options
    currencyEnabled: {
      type: Boolean,
      default: false,
    },
    isHeaderChecked: {
      type: Boolean,
      default: false,
    },
    isQrCodeDownloadEnabled: {
      type: Boolean,
      default: false,
    },
    searchEnabled: {
      type: Boolean,
      default: true,
    },
    currencyAttributes: {
      type: Object,
      default: () => null,
      validator(attr) {
        // todo: use better validation
        return typeof attr.root === 'string' && attr.def && attr.actual
      },
    },
    isExportEnabled: {
      type: Boolean,
      default: true,
    },
    isMultipleViewEnabled: {
      type: Boolean,
      default: false,
    },
    isAISyncEnabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    OtoSearch,
    MultipleTableViewButton,
    ExportAsDropdown,
    CurrencyDropdown,
    BaseProgressBar,
    SyncWithAIButton,
    PopSuggestion,
  },
  data: () => ({
    // props proxy
    proxy: {
      headers: [],
    },
    topLeftSlotWidth: null,
    layoutView: 'list',
    notificationConnection: null,
    exportRequestId: null,
    isExportLoading: false,
    exportInterval: null,
    exportInfo: {},
    progressPercentage: 0,
    isAiSyncInOperation: false,
    isAISyncStepChecking: false,
  }),
  computed: {
    getAllSelectCheckStatus() {
      return this.$store.getters['fsTable/getAllSelectCheckStatus']
    },
    reqBusy() {
      return this.$store.state.fsTable.req.busy
    },
    currentPage: {
      get() {
        return this.$store.getters['fsTable/getPage']
      },
      set(page) {
        // offset will be updated on the store based on the page
        this.$store.commit('fsTable/updatePage', page)
      },
    },
    currentLimit: {
      get() {
        return this.$store.getters['fsTable/getLimit']
      },
      set(val) {
        this.$store.commit('fsTable/updateLimit', val)
      },
    },
    currentOffset: {
      get() {
        return this.$store.getters['fsTable/getOffset']
      },
      set(val) {
        this.$store.commit('fsTable/updateOffset', val)
      },
    },
    currentTotal() {
      return this.$store.getters['fsTable/getTotal']
    },
    currentPaginationSummary() {
      return this.$store.getters['fsTable/getPaginationSummary']
    },
    currentSearch: {
      get() {
        return this.$store.getters['fsTable/getSearch']
      },
      set(val) {
        this.$store.commit('fsTable/updatePage', 1)
        this.$store.commit('fsTable/updateOffset', 0)
        this.$store.commit('fsTable/updateSearch', val)
      },
    },
    getQueryString() {
      // push to the fstable.state.qsa
      // watch in the table component
      // re-fetch
      // this.$log.debug(this.$store.getters['fsTable/getQueryString'])
      return this.$store.getters['fsTable/getQueryString']
    },
    getRes() {
      return this.$store.state.fsTable.res
    },
    getData() {
      return this.$store.state.fsTable.res.data
    },
    getMeta() {
      return this.$store.state.fsTable.res.meta
    },
    getProgress() {
      return this.progressPercentage
    },
  },
  async created() {
    //
    window.removeEventListener('resize', this.getTopLeftSlotWidth)
    window.addEventListener('resize', this.getTopLeftSlotWidth)
    this.getTopLeftSlotWidth()

    //
    const isReset = this.$store.state.fsTable.fstId !== this.fstId

    this.$store.dispatch('fsTable/init', {
      fstId: this.fstId,
      endpoint: this.endpoint,
      qso: this.qso,
      reset: isReset,
      currencyEnabled: this.currencyEnabled,
      currencyAttributes: this.currencyAttributes,
    })

    if (isReset) {
      // fetch data will fire meta, data & res events
      await this.fetchData()
    } else {
      const res = this.$store.state.fsTable.res
      this.$emit('meta', res.meta)
      this.$emit('data', res.data)
      // res event isn't available in this case
      // if we need to be consistent, we'll have to store axios response as well
      // for now, it's not important. res event is only helpful for debugging axios raw response
    }
  },
  mounted() {},
  watch: {
    url: {
      immediate: true,
      deep: false,
      handler(val) {
        this.$store.commit('fsTable/setEndpoint', val)
      },
    },
    headers: {
      immediate: true,
      deep: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.proxy.headers = this.getMappedHeaders(val)
        }
      },
    },
    layoutView: {
      immediate: true,
      deep: true,
      handler(val) {
        this.$emit('view', val)
      },
    },
    exportInfo: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data && data.progress_info) {
          if (data.progress_info.status !== 'in_progress') {
            clearInterval(this.exportInterval)
            this.isExportLoading = false

            if (
              data.progress_info.status === 'completed' &&
              data.export_record.file_url
            ) {
              if (!this.isAiSyncInOperation) {
                const a = document.createElement('a')
                a.style.display = 'none'
                a.href = data.export_record.file_url
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                this.$notify({
                  group: 'generic',
                  type: 'success',
                  title: 'Success',
                  text: 'File has been downloaded successfully',
                })
              } else {
                this.isAiSyncInOperation = false
                this.$notify({
                  group: 'generic',
                  type: 'success',
                  title: 'Success',
                  text: 'Successfully synced with AI',
                })
              }
            } else {
              if (!this.isAiSyncInOperation) {
                this.$notify({
                  group: 'bottomRight',
                  type: 'error',
                  title: 'Failed',
                  text: `${data.progress_info.failure_reason ??
                    'Failed to download'}`,
                })
              } else {
                this.isAiSyncInOperation = false
                this.$notify({
                  group: 'bottomRight',
                  type: 'error',
                  title: 'Failed',
                  text: `${data.progress_info.failure_reason ??
                    'Failed to sync'}`,
                })
              }
            }
          }
        }
      },
    },
    async getQueryString(updatedQueryString) {
      console.log({ watchQS: updatedQueryString })
      await this.fetchData(updatedQueryString)
    },
  },
  methods: {
    handleCancelExport() {
      this.$http
        .patch(useEndpoints.export.cancel(this.exportRequestId))
        .then(() => {
          this.$notify({
            group: 'generic',
            type: 'success',
            title: 'Success',
            text: 'Cancelled Successfully',
          })
        })
        .catch((err) => {
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: 'Failed',
            text: 'Failed to Cancel',
          })
          console.log('export-cancel-err', err.response)
        })
        .finally(() => {
          this.isExportLoading = false
          clearInterval(this.exportInterval)
        })
    },

    onCheckStepForSyncAI() {
      this.$store.commit('suggestiveStep/SET_STEP_TOPIC', STEP_TOPIC.AI)
      this.$store.commit(
        'suggestiveStep/SET_SUGGESTIONS',
        STEP_SUGGESTIONS[STEP_TOPIC.AI]
      )
      this.$store.commit(
        'suggestiveStep/SET_ORG_ID',
        this.$store.getters['auth/organizationInfo'].id || null
      )
      return new Promise((resolve) => {
        this.$store
          .dispatch('suggestiveStep/havePendingStepExist')
          .then((res) => {
            this.$store.commit(
              'suggestiveStep/SET_POPUP_VIEW_STATUS',
              res.isExist
            )
            resolve(res.isExist)
          })
      })
    },
    async onSyncWithAI() {
      this.isAISyncStepChecking = true
      await this.onCheckStepForSyncAI()
        .then((isExist) => {
          if (!isExist) {
            const sDate = this.$store.state?.fsTable?.qsc?.start_date
            const sTime = this.$store.state?.fsTable?.qsc?.start_time
            const eDate = this.$store.state?.fsTable?.qsc?.end_date
            const eTime = this.$store.state?.fsTable?.qsc?.end_time

            const orgStartDateTime = this.$store.getters[
              'auth/organizationInfo'
            ].created_at.substr(0, 19)

            const now = new Date()
            const orgEndDateTime = now.toISOString().substr(0, 19)

            const startingTime = sTime ? `T${sTime}Z` : 'T00:00:00Z'
            const startingDateTime =
              sDate && startingTime
                ? `${sDate}${startingTime}`
                : orgStartDateTime

            const endingTime = eTime ? `T${eTime}Z` : 'T23:59:59Z'
            const endingDateTime =
              eDate && endingTime ? `${eDate}${endingTime}` : orgEndDateTime

            const data = new FormData()
            data.append('entity_type', this.exportType)
            data.append('time_from', startingDateTime)
            data.append('time_to', endingDateTime)

            if (this.exportType === 3) {
              //vehicle-logs-type
              const vehicleId = this.$store.state?.vehicleDetails?.primaryKey
              data.append('vehicle_id', vehicleId)
            }

            this.$http
              .post(useEndpoints.aiSync.index(), data)
              .then((res) => {
                if (res.data) {
                  this.isAiSyncInOperation = true
                  this.isExportLoading = true
                  this.exportRequestId = res.data.id
                  this.exportInterval = setInterval(
                    this.fetchExportProgress,
                    5000
                  )
                  this.$notify(
                    {
                      group: 'generic',
                      type: 'default',
                      title: 'Processing, please wait...',
                      text:
                        'Please stay on the tab while the operation is being completed.',
                    },
                    3000
                  )
                }
              })
              .catch((err) => {
                console.log('export-request-err', { err })
                this.$notify(
                  {
                    group: 'bottomRight',
                    type: 'error',
                    title: 'Failed',
                    text: `${
                      err.response.data.message
                        ? err.response.data.message
                        : err.response.data
                    }`,
                  },
                  5000
                )
              })
          }
        })
        .finally(() => {
          this.isAISyncStepChecking = false
        })
    },
    onChangeView(event) {
      this.layoutView = event
    },
    onChangeCheckBox(event) {
      this.$store.commit('fsTable/onAllSelect', { value: event })
      this.$store.commit('fsTable/setListSelectAllStatus', event)
    },
    getQrCodeURL(qr_code) {
      const orgDomain = JSON.parse(localStorage.getItem('organization')).domain
      return `https://${orgDomain}/${qr_code || '--'}`
    },
    async fetchExportProgress() {
      await this.$http
        .get(useEndpoints.export.details(this.exportRequestId))
        .then((res) => {
          this.exportInfo = res.data
          this.progressPercentage =
            parseInt(this.exportInfo.progress_info.progress) || 0

          console.log('res-export', res.data.progress_info.progress)
        })
        .catch((err) => {
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: 'Failed',
            text: 'Failed to retrieve',
          })
          console.log('export-get-err', err.response)
        })
        .finally(() => {
          // this.disconnectToWebsocket()
          // this.isExportLoading = false
        })
    },
    async generatePDF(qrCodes) {
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: 'a4',
        compress: true,
        fontSize: 8,
        lineHeight: 1.2,
        autoPageBreak: true,
        margin: { top: 20, right: 20, bottom: 20, left: 20 },
      })
      const x = 26
      const y = 26
      const width = 56
      const height = 56
      for (let i = 0; i < qrCodes.length; i++) {
        let xPos = 0
        let yPos = 0

        xPos = x + (i % 5) * (width + x)
        yPos = y + Math.floor((i % 35) / 5) * (height + y)
        const qrCode = await QRCode.toDataURL(this.getQrCodeURL(qrCodes[i]))
        if (i % 35 === 0 && i > 0) {
          pdf.addPage()
        }
        pdf.addImage(qrCode, 'JPEG', xPos, yPos, width, height)
        pdf.setFontStyle('bold')
        pdf.text(
          qrCodes[i],
          this.getXpositionForTextInPdf(xPos + 8, qrCodes[i]),
          yPos + height + 2
        )
      }
      pdf.save(`vehicle-qrcodes-${qrCodes.length}.pdf`)
    },
    getXpositionForTextInPdf(x, text) {
      if (text.length === 2) {
        return x + 16
      } else if (text.length === 3) {
        return x + 15
      } else if (text.length === 4) {
        return x + 13
      } else if (text.length === 5) {
        return x + 10
      } else if (text.length === 6) {
        return x + 9
      } else if (text.length === 7) {
        return x + 8
      } else if (text.length === 8) {
        return x + 7
      } else if (text.length === 9) {
        return x + 5
      } else if (text.length === 10) {
        return x + 4
      } else if (text.length === 11) {
        return x + 2
      } else {
        return x
      }
    },
    onSort(sortingItem, sortingItemIndex) {
      // prevent sorting on disabled items (null)
      if (sortingItem.sort === null) {
        return
      }

      // update current item order value to either 'asc' or 'desc' to display arrows
      const currentOrder = this.proxy.headers[sortingItemIndex].order
      this.proxy.headers[sortingItemIndex].order = this.getOrder(currentOrder)

      // todo: commit to store
      // set sorting values to the query strign which will trigger a refetch
      const payload = {
        sort: this.proxy.headers[sortingItemIndex].sort,
        order: this.proxy.headers[sortingItemIndex].order,
      }
      this.$store.commit('fsTable/updateSortAndOrder', payload)

      // do a binary search & reset other sorting to null
      this.proxy.headers.forEach((x) => {
        if (x.text === sortingItem.text) return

        x.order = null
      })
    },
    // eslint-disable-next-line no-unused-vars
    onChangePage(page) {
      // this.$log.debug(pageNo)
      this.$scrollTo(`#${this.fstId}Container`, 200, {
        offset: -150,
      })
    },
    // onDateApply(event) {
    //   if (event.start.split('T')[0]) {
    //     this.proxy.queryString.start_date = event.start.split('T')[0]
    //   } else {
    //     this.proxy.queryString.start_date = event.start
    //   }

    //   if (event.end.split('T')[0]) {
    //     this.proxy.queryString.end_date = event.end.split('T')[0]
    //   } else {
    //     this.proxy.queryString.end_date = event.end
    //   }
    // },
    // onDateCancel() {
    //   this.proxy.queryString.start_date = ''
    //   this.proxy.queryString.end_date = ''
    // },
    getMappedHeaders(data) {
      return data.map((x) => {
        // todo: check for optional width
        // set default sort order
        // order null => not sorted yet || asc or desc => sorted -> show icon
        return { text: x.text, sort: x.sort, width: x.width, order: null }
      })
    },
    getOrder(currentOrder) {
      if (currentOrder === null) {
        // not sorted yet, use 'asc' order (by default, server returns in 'desc' order)
        return 'asc'
      }
      // already sorted, return the opposite order
      return currentOrder === 'asc' ? 'desc' : 'asc'
    },
    async fetchData(queryString = '') {
      if (queryString === '') {
        queryString = this.$store.getters['fsTable/getQueryString']
      }

      //--Note: Special Case--> It is maintained for only Notification filter
      if (queryString.split('&').includes('subcategory=IM')) {
        queryString = queryString.concat('&fetch_illegal_movement')
      }

      await this.$store
        .dispatch('fsTable/fetchData', queryString)
        .then((res) => {
          console.log({ fetchData: res })

          this.$emit('meta', res.data.meta)
          this.$emit('data', res.data.data)
          this.$emit('res', res)
        })
    },

    exportQrCode() {
      console.log('exportQrCode', this.getRes)
      const qrCodes = this.getRes.data.map((v) => {
        return v.qr_code
      })

      this.generatePDF(qrCodes)
    },
    async exportAs(type = 'excel') {
      this.progressPercentage = 0
      try {
        // const res = await this.$http.get(url)
        const data = this.$store.getters['fsTable/getData']
        const dataLength = data.length
        console.log(dataLength, data, Object.keys(data[0]))

        // console.log(data)
        const sDate = this.$store.state?.fsTable?.qsc?.start_date
        const sTime = this.$store.state?.fsTable?.qsc?.start_time
        const eDate = this.$store.state?.fsTable?.qsc?.end_date
        const eTime = this.$store.state?.fsTable?.qsc?.end_time

        const orgStartDateTime = this.$store.getters[
          'auth/organizationInfo'
        ].created_at.substr(0, 19)

        const now = new Date()
        const orgEndDateTime = now.toISOString().substr(0, 19)

        const dateRange =
          sDate && eDate ? `${sDate}_to_${eDate}` : `lifetime_data`
        const fstIdSnakeCase = this.fstId.replace(
          /[A-Z]/g,
          (letter) => `_${letter.toLowerCase()}`
        )

        if (
          !Array.isArray(data) &&
          typeof data[0] !== 'object' &&
          data[0] !== null
        ) {
          this.$notify({
            group: 'generic',
            type: 'error',
            title: 'Error occured!',
            text:
              "We couldn't fetch the data in proper format, please try again later.",
          })

          const consoleMsg =
            'First element of the data array is expected as an object, given: ' +
            typeof data[0]

          console.log(consoleMsg)
          throw new Error(consoleMsg)
        }

        if (!dataLength || dataLength < 1) {
          this.$notify({
            group: 'generic',
            type: 'error',
            title: 'Error occured!',
            text: 'No data found, please try again later.',
          })
          const consoleMsg = `Data length is: ${dataLength}. Reason could be the applied filter has actually no data or something wrong with the server`

          console.log(consoleMsg)
          throw new Error(consoleMsg)
        }

        if (type === 'excel') {
          await import(/* webpackChunkName: "exceljs" */ 'exceljs')
            .then((ExcelJS) => {
              // initiate workbook
              const workbook = new ExcelJS.Workbook()
              workbook.creator = 'Farhan Israq'
              workbook.created = new Date()
              workbook.lastModifiedBy = 'OTORide System'
              workbook.modified = new Date()

              // prepare the worksheet
              const worksheet = workbook.addWorksheet('Sheet 1', {
                headerFooter: {
                  firstFooter: 'Exported From OTORIde System',
                },
                pageSetup: { paperSize: 9 },
              })

              const columns = exportHeaderGroup[this.exportFor].map((x) => {
                return {
                  // convert the header to title case (from snake case)
                  header: x.title,
                  key: x.key,
                  width: x.title.length + 4,
                }
              })

              // define columns
              worksheet.columns = columns
              // insert columns
              for (let i = 0; i < dataLength; i++) {
                console.log(
                  'getTransformRowData',
                  getTransformRowData(data[i], this.exportFor),
                  data[i]
                )

                worksheet.addRow(
                  (i + 1,
                  getTransformRowData(
                    data[i],
                    this.exportFor,
                    this?.$org?.default_currency?.symbol
                  ))
                )
              }

              const fileName = `${fstIdSnakeCase}_otoride_export_${dateRange}.xlsx`

              workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                  new Blob([buffer], { type: 'application/octet-stream' }),
                  `${fileName}`
                )
              })

              // console.log(worksheet)
            })
            .catch((exceljsErr) => {
              // todo: failed toast
              console.warn({ exceljsErr })
            })
        } else if (type === 'csv') {
          if (this.exportFromURL) {
            const startingTime = sTime ? `T${sTime}Z` : 'T00:00:00Z'
            const startingDateTime =
              sDate && startingTime
                ? `${sDate}${startingTime}`
                : orgStartDateTime

            const endingTime = eTime ? `T${eTime}Z` : 'T23:59:59Z'
            const endingDateTime =
              eDate && endingTime ? `${eDate}${endingTime}` : orgEndDateTime

            const data = new FormData()
            data.append('entity_type', this.exportType)
            data.append('time_from', startingDateTime)
            data.append('time_to', endingDateTime)

            this.$http
              .post(useEndpoints.export.index(), data)
              .then((res) => {
                if (res.data) {
                  this.isExportLoading = true
                  this.exportRequestId = res.data.id
                  this.exportInterval = setInterval(
                    this.fetchExportProgress,
                    5000
                  )
                  this.$notify(
                    {
                      group: 'generic',
                      type: 'default',
                      title: 'Processing, please wait...',
                      text:
                        'Please stay on the tab while the operation is being completed.',
                    },
                    3000
                  )
                }
              })
              .catch((err) => {
                console.log('export-request-err', { err })
                this.$notify(
                  {
                    group: 'bottomRight',
                    type: 'error',
                    title: 'Failed',
                    text: `${
                      err.response.data.message
                        ? err.response.data.message
                        : err.response.data
                    }`,
                  },
                  5000
                )
              })
          } else {
            const blob = new Blob([this.csvMaker(data)], { type: 'text/csv' })
            const fileName = `${fstIdSnakeCase}_otoride_export_${dateRange}.csv`
            saveAs(blob, fileName)
          }
        } else {
          // todo: toast -> contact super admin
          throw new Error('Unknown export format')
        }
      } catch (err) {
        // todo: toast -> data could not be fetched
        console.log('export-err', { err })
      }
    },
    csvMaker(data) {
      const csvRows = []

      const headers = exportHeaderGroup[this.exportFor].map((x) => x.title)

      csvRows.push(headers.join(';'))
      for (let i = 0; i < data.length; i++) {
        const values = Object.values(
          getTransformRowData(data[i], this.exportFor)
        ).join(';')
        csvRows.push(values)
      }

      return csvRows.join('\n')
    },
    getTopLeftSlotWidth() {
      this.$nextTick(() => {
        this.topLeftSlotWidth = this.$refs?.topLeftSlot?.clientWidth || 0
        // console.log({ tlsw: this.topLeftSlotWidth })
      })
    },

    onChangeCurrency(currency) {
      this.$store.commit('fsTable/setCurrencyEnableFlag', {
        flag: this.currencyEnabled,
      })

      this.$store.dispatch('fsTable/switchCurrency', currency)
    },
  },
}
</script>

<style lang="scss" scoped>
@import './$fs-table.scss';
</style>
